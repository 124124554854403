<template>
  <div class="provider-my-documents">
    <b-card class="show-tabs-list-mobile">
      <b-tabs pills>
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span class="d-none d-sm-inline text-uppercase">{{ $t('General') }}</span>
          </template>
          <div class="col-md-12">
            <p class="text-danger mb-50">
              {{ $t('Note') }}:- {{ $t('To Modify/Upload the new document,delete the previous added document and proceed further.') }}
            </p>
            <p
              v-if="documentList.all"
              class="mb-25 text-center"
            >
              {{ $t('Upload') }} {{ documentList.all.name }}
            </p>
            <p v-if="documentList.all">
              {{ $t('You can upload') }} {{ documentList.all.file_type }}
            </p>
          </div>
          <div
            v-if="documentList.all"
            class="row general-image"
          >
            <div class="col-md-4">
              <image-input
                class="front-image"
                :default-image="getFrontImage(documentList.all.provider_document)"
              />
              <p class="text-center">
                {{ $t('Front') }}
              </p>
            </div>
            <div class="col-md-4">
              <image-input
                class="back-image"
                :default-image="getBackImage(documentList.all.provider_document)"
              />
              <p class="text-center">
                {{ $t('Back') }}
              </p>
            </div>
            <div class="col-md-4">
              <b-form-group :label="$t('Expiry Date')">
                <b-form-datepicker
                  v-model="documentList.all.provider_document.expires_at"
                  :date-disabled-fn="dateDisabled"
                  locale="en"
                />
              </b-form-group>
              <b-button
                v-if="!documentList.isAllDocumentAvailable"
                variant="primary"
                style="float: right;"
                @click="saveProviderDocument"
              >
                {{ $t('Save') }}
              </b-button>
              <b-button
                v-if="documentList.isAllDocumentAvailable"
                variant="danger"
                style="float: right;"
                @click="deleteDocument(documentList.all)"
              >
                {{ $t('Delete') }}
              </b-button>
            </div>
          </div>
        </b-tab>

        <b-tab
          v-for="(item, index) of providerServiceList"
          :key="index"
          @click="listDocuments(item.admin_service)"
        >
          <template #title>
            <span class="d-none d-sm-inline text-uppercase">{{ item.display_name }}</span>
          </template>
          <div class="col-md-12">
            <p class="text-danger mb-50">
              {{ $t('Note') }}:- {{ $t('To Modify/Upload the new document,delete the previous added document and proceed further.') }}
            </p>
            <p
              v-if="documentList.activeTab"
              class="mb-25 text-center"
            >
              {{ $t('Upload') }} {{ documentList.activeTab.name }}
            </p>
            <p v-if="documentList.activeTab">
              {{ $t('You can upload') }} {{ documentList.activeTab.file_type }}
            </p>
          </div>
          <div
            v-if="documentList.activeTab"
            class="row"
          >
            <div class="col-md-4">
              <image-input
                class="front-image"
                :default-image="getFrontImage(documentList.activeTab.provider_document)"
              />
              <p class="text-center">
                {{ $t('Front') }}
              </p>
            </div>
            <div class="col-md-4">
              <image-input
                class="back-image"
                :default-image="getBackImage(documentList.activeTab.provider_document)"
              />
              <p class="text-center">
                {{ $t('Back') }}
              </p>
            </div>
            <div class="col-md-4">
              <b-form-group :label="$t('Expiry Date')">
                <b-form-datepicker
                  v-model="documentList.activeTab.provider_document.expires_at"
                  :date-disabled-fn="dateDisabled"
                  locale="en"
                />
              </b-form-group>
              <b-button
                v-if="!documentList.isActiveDocumentAvailable"
                variant="primary"
                style="float: right;"
                @click="saveProviderDocument"
              >
                {{ $t('Save') }}
              </b-button>
              <b-button
                v-if="documentList.isActiveDocumentAvailable"
                variant="danger"
                style="float: right;"
                @click="deleteDocument(documentList.activeTab)"
              >
                {{ $t('Delete') }}
              </b-button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */

import { BCard, BTabs, BTab, BFormDatepicker, BFormGroup, BButton } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from './useProvider'
import ImageInput from '../users/shared/ImageInput.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    ImageInput,
    BFormDatepicker,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      providerServiceList: [],
      documentList: {
        all: null,
        activeTab: null,
        isAllDocumentAvailable: false,
        isActiveDocumentAvailable: false,
      },
    }
  },
  mounted() {
    this.providerServices()
    this.listDocuments('all')
  },
  methods: {
    dateDisabled(ymd, date) {
      return date < new Date()
    },
    getFrontImage(document) {
      if (document && document.url && document.url[0]) return window.SuperApp.helper.image(document.url[0].url)
      return null
    },
    getBackImage(document) {
      if (document && document.url && document.url[1]) return window.SuperApp.helper.image(document.url[1].url)
      return null
    },
    getUploadedDocument(array) {
      let uploadedFile = null
      array.forEach(item => {
        if (item && item.children && item.children.length === 1 && item.offsetWidth > 0 && item.offsetHeight > 0) {
          uploadedFile = item.children[0].files[0]
        }
      })
      return uploadedFile
    },
    saveProviderDocument() {
      const frontFile = this.getUploadedDocument(document.querySelectorAll('.front-image'))
      const backFile = this.getUploadedDocument(document.querySelectorAll('.back-image'))
      if (!frontFile) {
        showDangerNotification(this, 'Please select files to upload')
        return
      }
      const formData = new FormData()
      let expiryDate = this.documentList.all.provider_document.expires_at
      let documentId = this.documentList.all.id
      if (document.querySelector('.general-image').offsetWidth === 0 && document.querySelector('.general-image').offsetHeight === 0) {
        expiryDate = this.documentList.activeTab.provider_document.expires_at
        documentId = this.documentList.activeTab.id
      }
      formData.append('expires_at', expiryDate)
      formData.append('document_id', documentId)
      formData.append('file[0]', frontFile)
      if (backFile) {
        formData.append('file[1]', backFile)
      }

      showLoader()
      const { uploadProviderDocument } = useProviderUi()

      uploadProviderDocument(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.listDocuments(this.getActiveTab() === 'GENERAL' ? 'all' : this.getActiveTab())
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.listDocuments(this.getActiveTab() === 'GENERAL' ? 'all' : this.getActiveTab())
          showErrorNotification(this, error)
        })
    },
    providerServices() {
      showLoader()
      const { providerAdminServices } = useProviderUi()

      providerAdminServices()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerServiceList = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getActiveTab() {
      return document.querySelectorAll('.provider-my-documents ul.nav.nav-pills .active')[0] ? document.querySelectorAll('.provider-my-documents ul.nav.nav-pills .active')[0].innerText : 'GENERAL'
    },
    deleteDocument(props) {
      this.$swal({
        title: this.$t('You want to delete this document?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          showLoader()
          const { deleteProviderDocument } = useProviderUi()
          deleteProviderDocument(props.provider_document.id)
            .then(({ data }) => {
              hideLoader()
              if (data.statusCode === '200') {
                showSuccessNotification(this, data.message)
                this.listDocuments(this.getActiveTab() === 'GENERAL' ? 'all' : this.getActiveTab())
              } else {
                showDangerNotification(this, data.message)
              }
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    listDocuments(documentType) {
      showLoader()
      // eslint-disable-next-line no-param-reassign
      if (!documentType) documentType = 'all'
      this.documentList.activeTab = null
      const { providerListDocument } = useProviderUi()
      const formData = new FormData()
      formData.append('type', documentType === 'all' ? documentType : documentType.toUpperCase())
      providerListDocument(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (!data.responseData[0]) {
              showDangerNotification(this, 'Please ask admin to add the documents')
            } else {
              if (!data.responseData[0].provider_document) {
                // eslint-disable-next-line no-param-reassign
                data.responseData[0].provider_document = {}
              }
              if (documentType === 'all') this.documentList.all = data.responseData[0]
              else this.documentList.activeTab = data.responseData[0]
              const documentCount = Object.keys(data.responseData[0].provider_document).length
              if (documentType === 'all') {
                this.documentList.isAllDocumentAvailable = documentCount > 1
              } else {
                this.documentList.isActiveDocumentAvailable = documentCount > 1
              }
            }
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style>
.provider-my-documents ul.nav.nav-pills {
  justify-content: center;
}
</style>
